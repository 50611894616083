.questionnaire-container {
  --primaryColor: #47bea7;
  --secondaryColor: #666666;
  width: 80%;
  max-width: 750px;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem;
  /* align-self: center; */
  /* justify-self: center; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (max-width: 600px) {
  .questionnaire-container {
    width: 95%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
  }
}

.questionnaire-selection-container {
  width: 80%;
  max-width: 550px;
  padding: 3rem;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.8px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 1);
}

.selection-btn {
  padding: 25px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .questionnaire-selection-container {
    width: 95%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
  }

  .selection-btn {
    padding: 20px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.questionnaire-background {
  background-color: #47bea7;
  background: linear-gradient(135deg, rgb(56, 207, 192), #47bea7);
  min-height: 100vh;
  /* height: 100vh; */
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
}
.questionnaire-selection-background {
  background-color: #47bea7;
  background: linear-gradient(135deg, rgb(56, 207, 192), #47bea7);
  min-height: 100vh;
  /* height: 100vh; */
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
}

.app {
  background-color: rgb(250, 248, 248);
  font-family: 'Inter', sans-serif;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button {
  background-color: var(--primaryColor);
  height: 2.5rem;
  /* width: 30%; */
  width: 100%;
  max-width: 10rem;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.button-back {
  background-color: var(--secondaryColor);
  height: 2.5rem;
  /* width: 30%; */
  width: 100%;
  max-width: 10rem;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.button-back:disabled {
  background-color: rgb(0, 0, 0, 0);
}

.form-card {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
  /* padding: 10px; */
}

.inputField {
  width: 100%;
  height: 2.4rem;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 1vw;
  background-color: rgb(250, 248, 248);
  margin: 0;
}

.scaleZeroToTen {
  display: flex;
  flex-direction: column;
}

/* .ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
} */

.ant-steps-vertical {
  width: auto;
}
.ant-steps-horizontal {
  max-width: 40rem;
}

.ant-form {
  width: 100%;
}

.ant-picker-content tr,
.ant-picker-content td,
.ant-picker-content th {
  border: unset;
}

:where(.css-dev-only-do-not-override-scuqw7).ant-modal .ant-modal-footer {
  text-align: left;
}

/* .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
} */

/* .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
} */

/* .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primaryColor) !important;
} */

/* .ant-btn-primary {
  background-color: var(--primaryColor) !important;
} */

.ant-form-item-control-input-content > .ant-input {
  font-family: unset;
}

.ant-form-item-label {
  font-weight: 500;
}

.inputField::placeholder {
  color: rgb(160, 160, 160);
  /* padding-left: 1rem; */
}

.ant-form-item:last-child {
  margin-bottom: 0;
}

.inputField:focus {
  background-color: white;
  outline: 1px solid rgba(0, 0, 0, 0.3);
}
