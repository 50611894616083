.full-info-btn {
  background-color: rgb(70, 190, 167);
  color: white;
  border: none;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-size: 16px;
  background-color: #fff;
  color: #333;
  line-height: 1.5;
  /* padding: 15px 0px; */
  text-align: left;
}

#view {
  font-size: 14px;
  text-align: left;
}

.header_table {
}
.page_title {
  font-size: 26px;
  color: rgb(70, 190, 167);
}
.page_subtitle {
  font-size: 20px;
  color: #000;
  padding-top: 7px;
  padding-bottom: 10px;
}
.section_title {
  background-color: rgb(70, 190, 167);
  color: #fff;
  font-size: 20px;
  margin-top: 15px;
  border: 1px solid rgb(70, 190, 167);
}
.section_title_summary {
  background-color: rgb(70, 190, 167);
  color: #fff;
  font-size: 24px;
  margin-top: 15px;
  font-weight: bold;
  border: 1px solid rgb(70, 190, 167);
}
.sub_table {
  margin-top: 15px;
}
.sub_table .sub_t_tr td {
  padding-bottom: 10px;
}
.detail_table {
  border: #333 1px solid;
  margin-bottom: 15px;
}
.detail_table thead {
  background-color: rgb(70, 190, 167);
  color: #fff;
  border: 2px solid rgb(70, 190, 167);
}
.detail_table thead th {
  padding: 10px 15px;
  border-right: #808080 1px solid;
  border-bottom: #808080 1px solid;
}
.detail_table tbody tr {
  border-bottom: #808080 1px solid;
}
.detail_table tbody tr td {
  padding: 10px 15px;
  /* border-right: #808080 1px solid; */
}

.s_title {
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0px;
}
@media only screen and (max-width: 450px) {
  .detail_table {
    border-bottom: 0px;
  }
  .sub_table .sub_t_tr td {
    padding-bottom: 0px;
  }
  .row_wrap {
    display: flex;
    flex-direction: column;
  }
  .row_wrap td {
    border-bottom: #808080 1px solid;
    width: 100.3% !important;
  }
  .row_wrap td:last-child {
    border-bottom: 0px;
  }
  table tr td {
    width: 100.3%;
  }
  .word_break {
    word-break: break-word;
  }
  .w_100 {
    width: 100% !important;
  }
  .border_bot {
    border-bottom: #808080 1px solid;
  }
  .d_none {
    display: none;
  }
}
/* summary section design here */

* {
  margin: 0px;
  padding: 0px;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
/* body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  line-height: 1.5;
  padding: 15px 0px;
} */
/* th{text-align: left;} */
table {
  border-collapse: collapse;
  margin: 0 auto;
}
table td,
table th {
  border: 1px #777 solid;
  padding: 5px;
}
table.subTable tr:first-child td,
table.subTable tr:first-child th {
  border-top: none;
}
table.subTable tr:last-child td,
table.subTable tr:last-child th {
  border-bottom: none;
}
table.subTable tr td:first-child,
table.subTable tr th:first-child {
  border-left: none;
}
table.subTable tr td:last-child,
table.subTable tr th:last-child {
  border-right: none;
}
table td.input-field {
  padding: 0;
}
table td.input-field .form-control {
  padding: 5px;
  font-size: 14px;
  border: none;
  width: 100%;
}
.form-control:focus {
  border: none;
  outline: none;
}
.p-0 {
  padding: 0 !important;
}
.rightTH th {
  text-align: right;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1000px;
  }
}

* {
  margin: 0px;
  padding: 0px;
}
/* body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  line-height: 1.5;
  padding: 15px 0px;
} */
.header_table {
}
.page_title {
  font-size: 26px;
  color: rgb(70, 190, 167);
}
.page_subtitle {
  font-size: 20px;
  color: #000;
  padding-top: 7px;
  padding-bottom: 10px;
}
.section_title {
  background-color: rgb(70, 190, 167);
  color: #fff;
  font-size: 20px;
  margin-top: 15px;
}
.section_title_summary {
  background-color: rgb(70, 190, 167);
  color: #fff;
  font-size: 24px;
  margin-top: 15px;
  font-weight: bold;
}
.sub_table {
  margin-top: 15px;
}
.sub_table .sub_t_tr td {
  padding-bottom: 10px;
}
.detail_table {
  border: #333 1px solid;
  margin-bottom: 15px;
}

.s_title {
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0px;
}
@media only screen and (max-width: 450px) {
  .detail_table {
    border-bottom: 0px;
  }
  .sub_table .sub_t_tr td {
    padding-bottom: 0px;
  }
  .row_wrap {
    display: flex;
    flex-direction: column;
  }
  .row_wrap td {
    border-bottom: #808080 1px solid;
    width: 100.3% !important;
  }
  .row_wrap td:last-child {
    border-bottom: 0px;
  }
  table tr td {
    width: 100.3%;
  }
  .word_break {
    word-break: break-word;
  }
  .w_100 {
    width: 100% !important;
  }
  .border_bot {
    border-bottom: #808080 1px solid;
  }
  .d_none {
    display: none;
  }
}
/* summary section design here */

* {
  margin: 0px;
  padding: 0px;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
/* body {
  font-size: 14px;
  background-color: #fff;
  color: #000;
  line-height: 1.5;
  padding: 15px 0px;
} */
/* th{text-align: left;} */
table {
  border-collapse: collapse;
  margin: 0 auto;
}
table td,
table th {
  /* border: 1px #777 solid; */
  padding: 5px;
}
table.subTable tr:first-child td,
table.subTable tr:first-child th {
  border-top: none;
}
table.subTable tr:last-child td,
table.subTable tr:last-child th {
  border-bottom: none;
}
table.subTable tr td:first-child,
table.subTable tr th:first-child {
  border-left: none;
}
table.subTable tr td:last-child,
table.subTable tr th:last-child {
  border-right: none;
}
table td.input-field {
  padding: 0;
}
table td.input-field .form-control {
  padding: 5px;
  font-size: 14px;
  border: none;
  width: 100%;
}
.form-control:focus {
  border: none;
  outline: none;
}
.p-0 {
  padding: 0 !important;
}
.rightTH th {
  text-align: right;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

/* new css */
/* .detail_table th {
  min-width: 100px;
} */
.detail_table tbody tr td {
  padding: 5px;
}
.nw-sub-table th {
  text-align: right;
}
.lfw-table th {
  width: 120px;
}
.e-defi-table th {
  min-width: auto;
  text-align: center;
}
.e-defi-table td {
  text-align: center;
}
.text-center th {
  text-align: center;
}
.cstom-width th {
  min-width: auto;
}

.table-content table th,
table td {
  /* white-space: nowrap; */
  white-space: pre-wrap;
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1000px;
  }
}

/* Print */

@page {
  size: A4;
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media print {
  /* html, */
  body .tabulator,
  /* .App, */
  /* #root, */
  .tabulator-tableholder,
  /* .offcanvas.offcanvas-top, */
  /* .offcanvas-body, */
  #view {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible !important;
    page-break-inside: auto;
    page-break-after: always;
    background: white;
    font-size: 11px;
  }

  .tabulator-tableholder {
    height: unset !important;
    max-height: unset !important;
  }

  .tabulator {
    position: absolute;
  }

  /* .tabulator-footer {
    display: none;
  } */
}
