@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(210, 67%, 99%);
  --clr-primary-2: hsl(220, 39%, 26%);
  --clr-primary-3: rgb(70, 190, 167);
  --clr-primary-4: hsl(260, 74%, 75%);
  --clr-primary-5: hsl(260, 81%, 85%);
  /* --clr-primary-5: hsl(42, 92%, 55%); */
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(217, 40%, 96%);
  --clr-grey-2: hsl(241, 69%, 50%);
  --clr-grey-3: hsl(241, 76%, 24%);
  /* --clr-grey-3: hsl(209, 34%, 30%); */
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(225, 20%, 61%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(8, 100%, 63%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Poppins', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* background: var(--clr-primary-1); */
  background: var(--clr-grey-1);
  color: var(--clr--1);
  line-height: 1.5;
  font-size: 0.875rem;
  padding-right: 0 !important;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  /* letter-spacing: var(--spacing); */
  /* text-transform: capitalize; */
  /* line-height: 1.25; */
  line-height: 1;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  font-size: 1.15rem;
  margin-bottom: 1.25rem;
  color: var(--clr-primary-2);
}

@media screen and (min-width: 800px) {
  h1 {
    /* font-size: 4rem; */
    font-size: 3.2rem;
  }
  h2 {
    /* font-size: 2.5rem; */
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 0.8rem;
  }
  p {
    /* font-size: 0.95rem; */
    font-size: 1rem;
    color: var(--clr-primary-2);
  }

  body {
    /* font-size: 0.8rem; */
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

.center {
  max-width: 1172px;
  /* border: 1px solid yellow; */
  margin: 0 auto;
  /* padding: 1rem; */
}

.hero {
  /* border: 1px solid white; */
  border-bottom: 1px solid white;
  width: 95vw;
  max-width: 1172px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media screen and (min-width: 1172px) {
  .hero {
    justify-content: space-between;
  }
} */

html {
  scroll-behavior: smooth;
  /* scroll-padding-top: 750px; */
}

.btn-primary {
  background: rgb(70, 190, 167);
  border: none;
}

.btn-primary:hover {
  background: #555555;
  color: rgb(70, 190, 167);
  border: none;
}

.btn-primary:disabled {
  background: #555555;
  color: rgb(70, 190, 167);
  border: none;
}
