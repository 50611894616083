.form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  width: auto;
  gap: 1.5rem;
}

h1 {
  margin: 5px 0px;
  font-size: 1.8em;
}

p {
  margin: 5px 0px;
  font-size: medium;
}

.buttonContainer {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  padding-top: 1.5rem;
}

.headings {
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }
}
