@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(210, 67%, 99%);
  --clr-primary-2: hsl(220, 39%, 26%);
  --clr-primary-3: rgb(70, 190, 167);
  --clr-primary-4: hsl(260, 74%, 75%);
  --clr-primary-5: hsl(260, 81%, 85%);
  /* --clr-primary-5: hsl(42, 92%, 55%); */
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(217, 40%, 96%);
  --clr-grey-2: hsl(241, 69%, 50%);
  --clr-grey-3: hsl(241, 76%, 24%);
  /* --clr-grey-3: hsl(209, 34%, 30%); */
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(225, 20%, 61%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(8, 100%, 63%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Poppins', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* background: var(--clr-primary-1); */
  background: hsl(217, 40%, 96%);
  background: var(--clr-grey-1);
  color: var(--clr--1);
  line-height: 1.5;
  font-size: 0.875rem;
  padding-right: 0 !important;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  /* letter-spacing: var(--spacing); */
  /* text-transform: capitalize; */
  /* line-height: 1.25; */
  line-height: 1;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  font-size: 1.15rem;
  margin-bottom: 1.25rem;
  color: hsl(220, 39%, 26%);
  color: var(--clr-primary-2);
}

@media screen and (min-width: 800px) {
  h1 {
    /* font-size: 4rem; */
    font-size: 3.2rem;
  }
  h2 {
    /* font-size: 2.5rem; */
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 0.8rem;
  }
  p {
    /* font-size: 0.95rem; */
    font-size: 1rem;
    color: hsl(220, 39%, 26%);
    color: var(--clr-primary-2);
  }

  body {
    /* font-size: 0.8rem; */
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

.center {
  max-width: 1172px;
  /* border: 1px solid yellow; */
  margin: 0 auto;
  /* padding: 1rem; */
}

.hero {
  /* border: 1px solid white; */
  border-bottom: 1px solid white;
  width: 95vw;
  max-width: 1172px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media screen and (min-width: 1172px) {
  .hero {
    justify-content: space-between;
  }
} */

html {
  scroll-behavior: smooth;
  /* scroll-padding-top: 750px; */
}

.btn-primary {
  background: rgb(70, 190, 167);
  border: none;
}

.btn-primary:hover {
  background: #555555;
  color: rgb(70, 190, 167);
  border: none;
}

.btn-primary:disabled {
  background: #555555;
  color: rgb(70, 190, 167);
  border: none;
}

.Home_hero__ayNTY {
  /* border: 1px solid red; */
  /* width: 95vw; */
  /* max-width: 1200px; */
  /* height: 100vh; */
  height: 100%;
  margin: 0rem 1rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--clr-primary-2);
}

@media print {
  .Home_hero__ayNTY {
    display: block;
  }
}

.Home_table__3Ffpg {
  /* border: 1px solid red; */
  height: 100vh;
  /* margin: 0 auto; */
  /* margin-top: 2rem; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* color: var(--clr-primary-2); */
}

.Home_heroSection__X8h7B {
  /* border: 1px solid red; */
  /* padding: 0 1rem; */
  height: 100%;
}

.Home_heroSection__X8h7B h1 {
  /* font-weight: bold; */
  max-width: 400px;
  /* width: 20rem; */
  /* max-width: 30rem; */
}

.Home_heroSection__X8h7B h3 {
  color: var(--clr-red-dark);
  font-weight: bold;
}

.Home_heroSection__X8h7B p {
  /* border: 1px solid red; */
  max-width: 30rem;
  font-weight: bold;
  color: var(--clr-grey-5);
}

.Home_btn__3bZyE {
  border: transparent;
  padding: 1rem 2rem;
  background-color: var(--clr-primary-3);
  color: #fff;
  font-size: 1.15rem;
  font-weight: bold;
  letter-spacing: var(--spacing);
  border-radius: 30px;
  margin-top: 1rem;
  cursor: pointer;
}

.Home_btn__3bZyE:hover {
  transition: var(--transition);
  font-size: 1.25rem;
}

.Home_sidebarIcons__OwvpW {
  /* border: 1px solid #fff; */
  display: flex;
  justify-content: start;
}

.Home_sidebarIcons__OwvpW a {
  /* border: 1px solid white; */
  font-size: 1.7rem;
  /* margin: 1rem 0.5rem; */
  margin-right: 0.8rem;
  color: #fff;
  transition: all 0.3s linear;
}

.Home_sidebarIcons__OwvpW a:hover {
  color: var(--clr-red-light);
}

@media screen and (max-width: 879px) {
  .Home_heroIcons__3_hLj {
    display: none;
  }
}

.Home_animation__2jXnA {
  /* border: 2px solid red; */
  margin-left: 7rem;
}

.Home_animation__2jXnA img {
  /* max-width: 40rem; */
  max-width: 24rem;
}

@media screen and (max-width: 1340px) {
  .Home_animation__2jXnA {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 1172px) {
  .Home_animation__2jXnA {
    display: none;
  }
}

.Home_email__3xY_J {
  color: var(--clr-red-dark);
}

.Home_loginBtn__2RVAj {
  border: transparent;
  background: transparent;
  color: var(--clr-red-dark);
}

.Navbar_logo__1iFR8 {
  width: 200px;
}

.Navbar_navbar__2WdvJ {
  /* border: 5px solid red; */
  /* position: fixed; */
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  background: var(--clr-grey-1);
  /* background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); */
}

.Navbar_navCenter__1H_36 {
  /* border: 3px solid blue; */
  max-width: 1200px;
  margin: 0 auto;
  background: var(--clr-grey-1);
}

.Navbar_navHeader__20eV4 {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem 1rem 1.5rem;
  align-items: center;
  /* padding: 2rem 1rem 0 1rem; */
  /* border: 2px solid yellow; */
  z-index: 6;
}

.Navbar_navHeader__20eV4 h2 {
  /* border: 1px solid red; */
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.Navbar_navHeader__20eV4 a {
  color: var(--clr-primary-2);
}

.Navbar_navBtn__q1uVN {
  /* margin-bottom: 1rem; */
  background: transparent;
  border: transparent;
  margin-bottom: 1rem;
  color: var(--clr-primary-2);
  font-size: 2.4rem;
  cursor: pointer;
  transition: all 0.3s linear;
  z-index: 2;
}

.Navbar_navBtn__q1uVN:hover {
  /* color: aqua; */
  color: var(--clr-primary-4);
}

.Navbar_hideNavBtn__1tPgQ {
  /* display: none; */
  z-index: -1;
}

.Navbar_navListsBox__IATEc {
  /* border: 1px solid red; */
  height: 0;
  display: none;
  overflow: hidden;
  padding: 1rem 1.5rem 1rem 1.5rem;
  transition: all 0.1s linear;
  z-index: 6;
}

.Navbar_navLists__3YeyD {
  /* border: 3px solid blue; */
  margin: 0;
}

.Navbar_showLists__26Xc7 {
  height: 250px;
  transition: all 0.3s linear;
}

.Navbar_navList__vduTJ {
  /* border: 1px solid red; */
  display: block;
  /* border: 1px solid yellowgreen; */
  /* padding: 0.5rem 0.5rem; */
  padding-left: 1rem;
}

.Navbar_navList__vduTJ a {
  /* border: 1px solid aqua; */
  display: block;
  text-decoration: none;
  color: var(--clr-primary-2);
  font-size: 0.96rem;
}

.Navbar_navList__vduTJ:hover {
  /* background: darkkhaki; */
  background: var(--clr-red-light);
  /* padding-left: 3rem; */
  /* transition: all 0.3s linear; */
}

.Navbar_sidebar__3-Djm {
  position: fixed;
  top: 0;
  right: 0;
  /* border: 4px solid green; */
  background: var(--clr-primary-1);
  width: 70vw;
  max-width: 300px;
  height: 100%;
  -webkit-transform: translate(100%);
          transform: translate(100%);
  transition: all 0.3s linear;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.Navbar_sidebarHeader__2SdL8 {
  display: flex;
  justify-content: space-between;
  /* padding: 2rem 1rem; */
  padding: 1.2rem 0.8rem 0 0.8rem;
  align-items: center;
}

.Navbar_sideCloseBtn__1h5Lo {
  background: transparent;
  border: transparent;
  font-size: 3rem;
  color: var(--clr-primary-2);
  cursor: pointer;
  margin-bottom: 1rem;
}

.Navbar_sideCloseBtn__1h5Lo:hover {
  transition: all 0.1s linear;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  color: var(--clr-primary-4);
}

.Navbar_sidebarLists__ctcRY {
  padding-left: 0;
}

.Navbar_sidebarLists__ctcRY a {
  display: flex;
  align-items: center;
}

.Navbar_sidebarLists__ctcRY a svg {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.Navbar_showSidebar__1I-5e {
  -webkit-transform: translate(0);
          transform: translate(0);
}

.Navbar_sidebarList__1q3XC {
  font-size: 1.2rem;
}

.Navbar_sidebarList__1q3XC:hover {
  background: var(--clr-primary-4);
  transition: all 0.3s linear;
}

.Navbar_sidebarList__1q3XC a {
  /* border: 1px solid red; */
  display: block;
  padding: 0.8rem;
  color: var(--clr-primary-2);
  font-size: 0.99rem;
  /* font-weight: bold; */
  letter-spacing: var(--spacing);
}

.Navbar_sidebarList__1q3XC a:hover {
  padding: 1rem 3rem;
  transition: all 0.3s linear;
  color: var(--clr-primary-1);
  font-weight: bold;
}

.Navbar_sidebarIcons__2TlYN {
  display: flex;
  justify-content: center;
}

.Navbar_sidebarIcons__2TlYN a {
  /* border: 1px solid white; */
  font-size: 1.5rem;
  margin: 1rem 0.5rem;
  color: #fff;
  transition: all 0.3s linear;
}

.Navbar_sidebarIcons__2TlYN a:hover {
  color: var(--clr-red-light);
}

.Navbar_social-icons__iAYP6 a:hover {
  color: var(--clr-primary-1);
}

@media screen and (min-width: 879px) {
  .Navbar_navBtn__q1uVN {
    display: none;
  }
  .Navbar_navHeader__20eV4 h2 {
    /* border: 1px solid red; */
    /* padding-bottom: 1rem; */
    /* margin-top: 0.5rem; */
    /* margin-bottom: 2.5rem; */
  }

  .Navbar_navListsBox__IATEc {
    height: auto !important;
    display: block;
  }

  .Navbar_navLists__3YeyD {
    display: flex;
    align-items: center;
  }

  .Navbar_navCenter__1H_36 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Navbar_navList__vduTJ:hover {
    background: darkkhaki;
    /* padding: 0.5rem 1rem; */
    background: none;
    /* transition: all 0.3s linear; */
  }

  .Navbar_navList__vduTJ a:hover {
    color: var(--clr-red-light);
    transition: all 0.3s linear;
  }

  .Navbar_showSidebar__1I-5e {
    -webkit-transform: translate(100%);
            transform: translate(100%);
  }
}

.Navbar_myLogo__1qppR {
  width: 2rem;
}

.Navbar_active__1YPmI {
  visibility: visible;
  transition: all 0.5s;
}

.Navbar_hidden__1YGZw {
  visibility: hidden;
  transition: all 0.3s linear;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.full-info-btn {
  background-color: rgb(70, 190, 167);
  color: white;
  border: none;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-size: 16px;
  background-color: #fff;
  color: #333;
  line-height: 1.5;
  /* padding: 15px 0px; */
  text-align: left;
}

#view {
  font-size: 14px;
  text-align: left;
}

.header_table {
}
.page_title {
  font-size: 26px;
  color: rgb(70, 190, 167);
}
.page_subtitle {
  font-size: 20px;
  color: #000;
  padding-top: 7px;
  padding-bottom: 10px;
}
.section_title {
  background-color: rgb(70, 190, 167);
  color: #fff;
  font-size: 20px;
  margin-top: 15px;
  border: 1px solid rgb(70, 190, 167);
}
.section_title_summary {
  background-color: rgb(70, 190, 167);
  color: #fff;
  font-size: 24px;
  margin-top: 15px;
  font-weight: bold;
  border: 1px solid rgb(70, 190, 167);
}
.sub_table {
  margin-top: 15px;
}
.sub_table .sub_t_tr td {
  padding-bottom: 10px;
}
.detail_table {
  border: #333 1px solid;
  margin-bottom: 15px;
}
.detail_table thead {
  background-color: rgb(70, 190, 167);
  color: #fff;
  border: 2px solid rgb(70, 190, 167);
}
.detail_table thead th {
  padding: 10px 15px;
  border-right: #808080 1px solid;
  border-bottom: #808080 1px solid;
}
.detail_table tbody tr {
  border-bottom: #808080 1px solid;
}
.detail_table tbody tr td {
  padding: 10px 15px;
  /* border-right: #808080 1px solid; */
}

.s_title {
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0px;
}
@media only screen and (max-width: 450px) {
  .detail_table {
    border-bottom: 0px;
  }
  .sub_table .sub_t_tr td {
    padding-bottom: 0px;
  }
  .row_wrap {
    display: flex;
    flex-direction: column;
  }
  .row_wrap td {
    border-bottom: #808080 1px solid;
    width: 100.3% !important;
  }
  .row_wrap td:last-child {
    border-bottom: 0px;
  }
  table tr td {
    width: 100.3%;
  }
  .word_break {
    word-break: break-word;
  }
  .w_100 {
    width: 100% !important;
  }
  .border_bot {
    border-bottom: #808080 1px solid;
  }
  .d_none {
    display: none;
  }
}
/* summary section design here */

* {
  margin: 0px;
  padding: 0px;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
/* body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  line-height: 1.5;
  padding: 15px 0px;
} */
/* th{text-align: left;} */
table {
  border-collapse: collapse;
  margin: 0 auto;
}
table td,
table th {
  border: 1px #777 solid;
  padding: 5px;
}
table.subTable tr:first-child td,
table.subTable tr:first-child th {
  border-top: none;
}
table.subTable tr:last-child td,
table.subTable tr:last-child th {
  border-bottom: none;
}
table.subTable tr td:first-child,
table.subTable tr th:first-child {
  border-left: none;
}
table.subTable tr td:last-child,
table.subTable tr th:last-child {
  border-right: none;
}
table td.input-field {
  padding: 0;
}
table td.input-field .form-control {
  padding: 5px;
  font-size: 14px;
  border: none;
  width: 100%;
}
.form-control:focus {
  border: none;
  outline: none;
}
.p-0 {
  padding: 0 !important;
}
.rightTH th {
  text-align: right;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1000px;
  }
}

* {
  margin: 0px;
  padding: 0px;
}
/* body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  line-height: 1.5;
  padding: 15px 0px;
} */
.header_table {
}
.page_title {
  font-size: 26px;
  color: rgb(70, 190, 167);
}
.page_subtitle {
  font-size: 20px;
  color: #000;
  padding-top: 7px;
  padding-bottom: 10px;
}
.section_title {
  background-color: rgb(70, 190, 167);
  color: #fff;
  font-size: 20px;
  margin-top: 15px;
}
.section_title_summary {
  background-color: rgb(70, 190, 167);
  color: #fff;
  font-size: 24px;
  margin-top: 15px;
  font-weight: bold;
}
.sub_table {
  margin-top: 15px;
}
.sub_table .sub_t_tr td {
  padding-bottom: 10px;
}
.detail_table {
  border: #333 1px solid;
  margin-bottom: 15px;
}

.s_title {
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0px;
}
@media only screen and (max-width: 450px) {
  .detail_table {
    border-bottom: 0px;
  }
  .sub_table .sub_t_tr td {
    padding-bottom: 0px;
  }
  .row_wrap {
    display: flex;
    flex-direction: column;
  }
  .row_wrap td {
    border-bottom: #808080 1px solid;
    width: 100.3% !important;
  }
  .row_wrap td:last-child {
    border-bottom: 0px;
  }
  table tr td {
    width: 100.3%;
  }
  .word_break {
    word-break: break-word;
  }
  .w_100 {
    width: 100% !important;
  }
  .border_bot {
    border-bottom: #808080 1px solid;
  }
  .d_none {
    display: none;
  }
}
/* summary section design here */

* {
  margin: 0px;
  padding: 0px;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
/* body {
  font-size: 14px;
  background-color: #fff;
  color: #000;
  line-height: 1.5;
  padding: 15px 0px;
} */
/* th{text-align: left;} */
table {
  border-collapse: collapse;
  margin: 0 auto;
}
table td,
table th {
  /* border: 1px #777 solid; */
  padding: 5px;
}
table.subTable tr:first-child td,
table.subTable tr:first-child th {
  border-top: none;
}
table.subTable tr:last-child td,
table.subTable tr:last-child th {
  border-bottom: none;
}
table.subTable tr td:first-child,
table.subTable tr th:first-child {
  border-left: none;
}
table.subTable tr td:last-child,
table.subTable tr th:last-child {
  border-right: none;
}
table td.input-field {
  padding: 0;
}
table td.input-field .form-control {
  padding: 5px;
  font-size: 14px;
  border: none;
  width: 100%;
}
.form-control:focus {
  border: none;
  outline: none;
}
.p-0 {
  padding: 0 !important;
}
.rightTH th {
  text-align: right;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

/* new css */
/* .detail_table th {
  min-width: 100px;
} */
.detail_table tbody tr td {
  padding: 5px;
}
.nw-sub-table th {
  text-align: right;
}
.lfw-table th {
  width: 120px;
}
.e-defi-table th {
  min-width: auto;
  text-align: center;
}
.e-defi-table td {
  text-align: center;
}
.text-center th {
  text-align: center;
}
.cstom-width th {
  min-width: auto;
}

.table-content table th,
table td {
  /* white-space: nowrap; */
  white-space: pre-wrap;
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1000px;
  }
}

/* Print */

@page {
  size: A4;
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media print {
  /* html, */
  body .tabulator,
  /* .App, */
  /* #root, */
  .tabulator-tableholder,
  /* .offcanvas.offcanvas-top, */
  /* .offcanvas-body, */
  #view {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible !important;
    page-break-inside: auto;
    page-break-after: always;
    background: white;
    font-size: 11px;
  }

  .tabulator-tableholder {
    height: unset !important;
    max-height: unset !important;
  }

  .tabulator {
    position: absolute;
  }

  /* .tabulator-footer {
    display: none;
  } */
}

.form-container {
  border: 4px solid green;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  border: 1px dashed blue;
}

.label {
  display: block;
}

.LogoHeader_logoBar__1Ix4f {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
  /* height: 7vh; */
}

.LogoHeader_logo__26hW1 {
  height: 50px;
  /* width: 25px; */
  /* padding: 5px; */
}

.LogoHeader_fontlogo__3A0Xw {
  font-size: x-large;
  margin: 0;
}

.questionnaire-container {
  --primaryColor: #47bea7;
  --secondaryColor: #666666;
  width: 80%;
  max-width: 750px;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem;
  /* align-self: center; */
  /* justify-self: center; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (max-width: 600px) {
  .questionnaire-container {
    width: 95%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
  }
}

.questionnaire-selection-container {
  width: 80%;
  max-width: 550px;
  padding: 3rem;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.8px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 1);
}

.selection-btn {
  padding: 25px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .questionnaire-selection-container {
    width: 95%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
  }

  .selection-btn {
    padding: 20px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.questionnaire-background {
  background-color: #47bea7;
  background: linear-gradient(135deg, rgb(56, 207, 192), #47bea7);
  min-height: 100vh;
  /* height: 100vh; */
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
}
.questionnaire-selection-background {
  background-color: #47bea7;
  background: linear-gradient(135deg, rgb(56, 207, 192), #47bea7);
  min-height: 100vh;
  /* height: 100vh; */
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
}

.app {
  background-color: rgb(250, 248, 248);
  font-family: 'Inter', sans-serif;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button {
  background-color: var(--primaryColor);
  height: 2.5rem;
  /* width: 30%; */
  width: 100%;
  max-width: 10rem;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.button-back {
  background-color: var(--secondaryColor);
  height: 2.5rem;
  /* width: 30%; */
  width: 100%;
  max-width: 10rem;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.button-back:disabled {
  background-color: rgb(0, 0, 0, 0);
}

.form-card {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  /* padding: 10px; */
}

.inputField {
  width: 100%;
  height: 2.4rem;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 1vw;
  background-color: rgb(250, 248, 248);
  margin: 0;
}

.scaleZeroToTen {
  display: flex;
  flex-direction: column;
}

/* .ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
} */

.ant-steps-vertical {
  width: auto;
}
.ant-steps-horizontal {
  max-width: 40rem;
}

.ant-form {
  width: 100%;
}

.ant-picker-content tr,
.ant-picker-content td,
.ant-picker-content th {
  border: unset;
}

:where(.css-dev-only-do-not-override-scuqw7).ant-modal .ant-modal-footer {
  text-align: left;
}

/* .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
} */

/* .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
} */

/* .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primaryColor) !important;
} */

/* .ant-btn-primary {
  background-color: var(--primaryColor) !important;
} */

.ant-form-item-control-input-content > .ant-input {
  font-family: unset;
}

.ant-form-item-label {
  font-weight: 500;
}

.inputField::-webkit-input-placeholder {
  color: rgb(160, 160, 160);
  /* padding-left: 1rem; */
}

.inputField::placeholder {
  color: rgb(160, 160, 160);
  /* padding-left: 1rem; */
}

.ant-form-item:last-child {
  margin-bottom: 0;
}

.inputField:focus {
  background-color: white;
  outline: 1px solid rgba(0, 0, 0, 0.3);
}

.LandingFormPage_form__3NR2- {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1;
  flex-direction: column;
  width: auto;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

h1 {
  margin: 5px 0px;
  font-size: 1.8em;
}

p {
  margin: 5px 0px;
  font-size: medium;
}

.LandingFormPage_buttonContainer__1ok6Z {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  justify-content: space-between;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  padding-top: 1.5rem;
}

.LandingFormPage_headings__21xxA {
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }
}

.Form_container__2w_A- {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  /* padding: 10px; */
  /* row-gap: 10px; */
  width: 100%;
}

.Form_container_row__1BHBG {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  /* margin: 10px; */
  padding: 0px;
  grid-gap: 3.5vw;
  gap: 3.5vw;
}

.Form_headings__1fsDQ {
  align-items: center;
  max-width: -webkit-max-content;
  max-width: max-content;
  width: 100%;
}

.Form_headings__1fsDQ p {
  color: rgba(0, 0, 0, 0.6);
}

.Form_section__2Nvli {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 25rem;
  max-width: 95vw;
}

.Form_subtext__3tsmx {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8rem;
  margin-left: 10px;
}

.Form_staticInput__3RWmj {
  height: 1rem;
  max-width: 6.5rem;
  width: 30%;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.4);
  background-color: aliceblue;
  padding: 0.75rem;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: none;
  border-radius: 6px 0px 0px 6px;
  text-align: start;
}

.Form_urlfield__Pa73x {
  width: 70%;
  max-width: 95vw;
  border-radius: 0px 6px 6px 0px;
}

.Form_useCard__1b8nD {
  max-width: 8rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1.2rem;
  cursor: pointer;
  transition: all ease-in-out 180ms;
}

.Form_useCard__1b8nD:hover {
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.Form_active__1qWSk {
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.Form_icon__bl5R_ {
  width: 25px;
  height: 25px;
}

.Form_title__2K9hJ {
  font-size: 16px;
  color: black;
}

.Form_desc__1kva4 {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
}

.Form_bigIcon__wVavz {
  width: 100px;
  height: 100px;
  color: var(--primaryColor);
  margin-bottom: 3vh;
}

/* Divider */
.Form_divider_title__1VjX9 {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: 1px solid #47bea7;
  /* border: 1px solid #47bea7; */

  /* border-radius: 6px; */
  color: #47bea7;
  text-align: center;
  padding: 0.4rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.Form_divider_subtitle__1dizu {
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  /* border: 1px solid #47bea7; */
  /* border-radius: 6px; */
  color: #47bea7;
  text-align: left;
  /* padding: 0.4rem; */
  margin-bottom: 0.6rem;
  text-transform: uppercase;
}

@media screen and (max-width: 350px) {
  .Form_staticInput__3RWmj {
    font-size: 0.5rem;
  }
}

