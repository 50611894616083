.form-container {
  border: 4px solid green;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  border: 1px dashed blue;
}

.label {
  display: block;
}
